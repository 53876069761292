import { Navigate, Outlet, useParams } from "react-router-dom";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useContractsSummary } from "@synota-io/synota-shared-ui";
import { RequiredAddressModal } from "./components/RequiredAddressModal";
import { useRequiredAddressModal } from "./hooks/useRequiredAddressModal";
import { CONTRACTS_PATH } from "../../paths";
import { LoadingPage } from "../../shared/pages/LoadingPage";
import { Stack, Box } from "@mui/material";
import { ContractMenu } from "./components/ContractMenu";

export const ContractsOutlet = () => {
  const requiredAddressModal = useRequiredAddressModal();

  const { contractId } = useParams();
  const { find, isLoading } = useContractsSummary();

  const contract = find(contractId);

  if (contractId && !isLoading && !contract) {
    return <Navigate to={CONTRACTS_PATH} />;
  }

  return (
    <LoadingPage>
      <Stack direction={{ sm: "row" }} alignItems="start" pb={1}>
        <Breadcrumbs />
        <Box flexGrow={1} />
        <Box alignSelf={{ sm: "auto", xs: "end" }}>
          <ContractMenu contract={contract} />
        </Box>
      </Stack>
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
    </LoadingPage>
  );
};
