import {
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode } from "react";
import {
  ActionButton,
  AutoEllipsis,
  BodyCell,
  BodyRow,
  Cell,
  ChangeLogRow,
  FormSubmit,
  HeadCell,
  HeadRow,
  REPORT_ENDPOINTS,
  ReportFiltersToolbar,
  ReportLayout,
  StickyTableContainer,
  formatDate,
  formateDateTime,
  useChangeLog,
  useReportCsv,
  useReportFiltersForm,
  useWindowSize,
} from "@synota-io/synota-shared-ui";
import { Download } from "@mui/icons-material";

const defaultDates = {
  startDate: null,
  endDate: null,
};

interface ChangeLogTableField {
  key: string;
  color?: TypographyProps["color"];
  value: (row: ChangeLogRow) => ReactNode;
}

export const ChangeLogTable = () => {
  useWindowSize();

  const tableFields: ChangeLogTableField[] = [
    {
      value: (item) => (
        <AutoEllipsis maxLine={2}>{formateDateTime(item.date_changed) || ""}</AutoEllipsis>
      ),
      key: "Date of Change (UTC)",
    },
    {
      value: (item) => (
        <AutoEllipsis maxLine={2}>{formatDate(item.effective_date) || ""}</AutoEllipsis>
      ),
      key: "Effective Date",
    },
    {
      value: (item) => <AutoEllipsis maxLine={2}>{item.action_description}</AutoEllipsis>,
      key: "Description",
    },
    {
      value: (item) => <AutoEllipsis maxLine={2}>{item.previous_value || ""}</AutoEllipsis>,
      key: "Previous value",
    },
    {
      value: (item) => <AutoEllipsis maxLine={1}>{item.new_value || ""}</AutoEllipsis>,
      key: "New value",
    },
    {
      value: (item) => <AutoEllipsis>{item.changed_by}</AutoEllipsis>,
      key: "Changed by",
    },
    {
      value: (item) => <AutoEllipsis maxLine={2}>{item.contract_name || "N/A"}</AutoEllipsis>,
      key: "Contract",
    },
  ];

  const { control, watch } = useReportFiltersForm({
    defaultReport: REPORT_ENDPOINTS.CHANGELOG,
    defaultDates,
  });

  const values = watch();

  const {
    params: input,
    download,
    error: csvError,
    isFetching: isFetchingCsv,
  } = useReportCsv({ values });

  const { items, fetchNextPage, hasNextPage, isFetching } = useChangeLog(input);

  return (
    <ReportLayout
      title="Changelog"
      actions={
        <ActionButton
          isLoading={isFetchingCsv}
          disabled={Boolean(csvError || !items?.length)}
          title="Download as CSV"
          color="primary"
          size="small"
          onClick={() => download()}
        >
          <Download />
        </ActionButton>
      }
    >
      <ReportFiltersToolbar control={control} />
      <Slide in direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {tableFields.map((field) => (
                      <HeadCell key={field.key}>
                        <AutoEllipsis maxLine={2}>{field.key}</AutoEllipsis>
                      </HeadCell>
                    ))}
                  </HeadRow>
                </TableHead>
                <TableBody>
                  {items &&
                    items.map((item) => {
                      return (
                        <BodyRow key={item.key}>
                          {tableFields.map((field) => (
                            <Cell key={field.key}>
                              <Typography variant="subtitle4" color={field.color}>
                                {field.value(item)}
                              </Typography>
                            </Cell>
                          ))}
                        </BodyRow>
                      );
                    })}
                </TableBody>
                {!isFetching && !items.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No changes found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>
            </StickyTableContainer>
          </CardContent>
        </Card>
      </Slide>
      <Slide in direction="up">
        <Stack direction="row">
          <FormSubmit
            isLoading={isFetching}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
            variant="contained"
          >
            {isFetching || hasNextPage ? "Load More" : "No more results"}
          </FormSubmit>
        </Stack>
      </Slide>
    </ReportLayout>
  );
};
