import { Download } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import {
  ActionButton,
  DateField,
  dayjs,
  Dayjs,
  IContract,
  isDayjs,
  useContractMonthlySummary,
  useDefaultError,
} from "@synota-io/synota-shared-ui";
import { useForm, useWatch } from "react-hook-form";

interface Props {
  contract: IContract | null;
}

interface MonthlySummaryFields {
  contract: IContract | null;
  startDate: Dayjs | null;
}

export const ContractMonthlySummary = ({ contract }: Props) => {
  const { control, formState } = useForm<MonthlySummaryFields>({
    mode: "all",
    resolver: (values) => {
      if (
        values.contract &&
        values.startDate
          ?.add(1, "second")
          .isAfter(dayjs(values.contract.beginDate).startOf("month")) &&
        values.startDate.isBefore(dayjs().endOf("day"))
      ) {
        return {
          values,
          errors: {},
        };
      }

      return {
        values,
        errors: { startDate: { message: "Can't use a date before the contract began" } },
      };
    },
    values: {
      contract,
      startDate: dayjs().startOf("month"),
    },
  });

  const values = useWatch<MonthlySummaryFields>({ control });

  const { fetch, isPending, error } = useContractMonthlySummary({
    contract,
    startDate: isDayjs(values.startDate) ? values.startDate.startOf("month") : null,
  });

  useDefaultError(error, "There was a problem downloading the monthly summary, please try again");

  const isMenuDisabled = !contract || isPending;

  return (
    <Card>
      <CardContent>
        <Stack
          flexGrow={1}
          alignItems={{ xs: "start", lg: "center" }}
          direction={{ xs: "column", lg: "row" }}
          spacing={{ xs: 2, lg: 8 }}
        >
          <Typography sx={{ whiteSpace: "nowrap" }} variant="h5">
            Monthly Summary Report
          </Typography>
          <Stack flexGrow={1} direction="row" width="100%" spacing={{ xs: 4, lg: 8 }}>
            <DateField
              variant="filled"
              maxDate={dayjs()}
              minDate={contract ? dayjs(contract.beginDate).startOf("month") : undefined}
              disabled={isMenuDisabled}
              control={control}
              name="startDate"
              slotProps={{ textField: { size: "small" } }}
              views={["year", "month"]}
              label="Select Month"
              fullWidth
            />
            <Stack flexGrow={1}>
              <ActionButton
                color="primary"
                title="Download Monthly Summary"
                size="small"
                type="submit"
                onClick={fetch}
                isLoading={isPending}
                disabled={isMenuDisabled || !formState.isValid}
              >
                <Download fontSize="small" />
              </ActionButton>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
