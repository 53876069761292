import { Box } from "@mui/material";
import {
  ButtonRow,
  CreateAdditionalChargeInput,
  DateField,
  Dayjs,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  NumericField,
  UseModalProps,
  dayjs,
  isDayjs,
  useAccount,
  useAdditionalChargesMutations,
  useModal,
  ContractSearchField,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import { ConfirmChargeModal } from "./ConfirmChargeModal";

export interface AdditionalChargeFormValues {
  contract: { uuid: string; name: string } | null;
  usdAmount: number;
  description: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  title: string;
  accountNumber: string;
  referenceId: string;
}

export const createInvoiceSchema = yup.object({
  contract: yup
    .object({
      uuid: yup.string().required("Invalid contract"),
      name: yup.string(),
    })
    .required("Contract is required"),
  title: yup.string().required("A title is required"),
  accountNumber: yup.string(),
  description: yup.string(),
  startDate: yup.object().required("Period start is required"),
  endDate: yup.object().required("Period end is required"),
  referenceId: yup.string(),
  usdAmount: yup
    .number()
    .typeError("USD Amount is required")
    .min(0, "USD Amount is required")
    .required("USD Amount is required"),
});

export const CreateAdditionalChargeFormModal = ({
  open,
  onClose,
  onSuccess,
}: UseModalProps & { onSuccess: () => void }) => {
  const { jwt } = useAccount();
  const { isPending, createCharge } = useAdditionalChargesMutations();
  const [hasSetEndDate, setHasSetEndDate] = useState(false);
  const [createInput, setCreateInput] = useState<CreateAdditionalChargeInput | null>(null);

  const { control, handleSubmit, setValue, trigger } = useForm<AdditionalChargeFormValues>({
    resolver: yupResolver(createInvoiceSchema) as any,
    mode: "all",
    defaultValues: {
      accountNumber: "",
      description: "",
      contract: null,
      endDate: dayjs(),
      startDate: dayjs(),
      referenceId: "",
      title: "",
      usdAmount: "" as unknown as number,
    },
  });

  const values = useWatch<AdditionalChargeFormValues>({ control });

  const confirmModal = useModal();

  const onSubmit = handleSubmit(
    ({
      usdAmount,
      contract,
      endDate,
      startDate,
      description,
      title,
      referenceId,
      accountNumber,
    }) => {
      if (!contract) {
        return;
      }
      setCreateInput({
        jwt,
        amount: usdAmount,
        contract_shared_uuid: contract.uuid,
        end_time: (endDate as Dayjs).toISOString(),
        begin_time: (startDate as Dayjs).toISOString(),
        reference_id_po_number: referenceId,
        general_ledger_account_number: accountNumber,
        header_title: title,
        description,
      });
      confirmModal.onOpen();
    },
  );

  const onConfirm = () => {
    if (!createInput) {
      return;
    }
    createCharge(createInput, {
      onSuccess: () => {
        onSuccess();
        confirmModal.onClose();
        onClose();
      },
    });
  };

  return (
    <>
      <Modal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        title="Create new ad hoc invoice for contract"
        actions={
          <ButtonRow width="100%">
            <FormButton
              variant="text"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </FormButton>
            <Box flexGrow={1} display="flex">
              <FormSubmit isLoading={isPending} form="create-additional-charge-form" fullWidth />
            </Box>
          </ButtonRow>
        }
      >
        <Form id="create-additional-charge-form" onSubmit={onSubmit}>
          <FormStep>
            <ContractSearchField
              control={control}
              size="small"
              header="Choose a contract"
              placeholder="Select here"
              name="contract"
              fullWidth
            />

            <InputField
              size="small"
              header="Header/Title"
              required
              fullWidth
              control={control}
              name="title"
            />
            <ButtonRow>
              <DateField
                header="Service Date(s)"
                size="small"
                label="Start date"
                fullWidth
                maxDate={isDayjs(values.endDate) ? values.endDate : undefined}
                control={control}
                name="startDate"
                onChange={(value) => {
                  if (value && !hasSetEndDate) {
                    setValue("endDate", value);
                    trigger("endDate");
                  }
                }}
              />
              <DateField
                fullWidth
                header="&nbsp;"
                size="small"
                minDate={isDayjs(values.startDate) ? values.startDate : undefined}
                label="End date"
                onChange={() => {
                  setHasSetEndDate(true);
                }}
                control={control}
                name="endDate"
              />
            </ButtonRow>
            <NumericField
              header="Amount"
              size="small"
              fullWidth
              autoComplete="off"
              required
              slotProps={{ input: { endAdornment: "$" } }}
              control={control}
              name="usdAmount"
            />
            <ButtonRow direction={{ sm: "row" }}>
              <InputField
                size="small"
                header="Reference ID/PO No."
                autoComplete="off"
                fullWidth
                control={control}
                name="referenceId"
              />
              <InputField
                size="small"
                header="General Ledger Account Number"
                fullWidth
                control={control}
                name="accountNumber"
              />
            </ButtonRow>
            <InputField
              size="small"
              header="Description"
              fullWidth
              control={control}
              name="description"
            />
          </FormStep>
        </Form>
      </Modal>
      {createInput && confirmModal.open && (
        <ConfirmChargeModal
          isLoading={isPending}
          charge={createInput}
          contract={values.contract}
          onConfirm={onConfirm}
          {...confirmModal}
        />
      )}
    </>
  );
};
