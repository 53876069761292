import { Skeleton } from "@mui/material";
import { IContract, useAccount, useStatementDataMetrics } from "@synota-io/synota-shared-ui";
import { ConsumerMetricsTable } from "./ConsumerMetricsTable";
import { SupplierMetricsTable } from "./SupplierMetricsTable";

interface Props {
  contract?: IContract | null;
  isLoading?: boolean;
}

export const MetricsTable = ({ contract, isLoading }: Props) => {
  const { isConsumer, isSupplier } = useAccount();
  const metrics = useStatementDataMetrics({ contract });

  if (isLoading || metrics.isLoading) {
    return <Skeleton variant="rounded" height={220} />;
  }

  if (isConsumer) {
    return <ConsumerMetricsTable contract={contract} statementDataMetrics={metrics} />;
  }

  if (isSupplier) {
    return <SupplierMetricsTable contract={contract} statementDataMetrics={metrics} />;
  }

  return null;
};
