import {
  BadgeTypography,
  DATE_FORMAT_DEFAULT,
  DetailsList,
  DetailsListItem,
  IAdditionalCharge,
  Link,
  dayjs,
  formatDollarAmount,
  useAccount,
  useCurrencyConverter,
} from "@synota-io/synota-shared-ui";
import { generatePath } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";
import {
  CHARGE_PAYMENT_STATUS_COLOR_MAP,
  CHARGE_PAYMENT_STATUS_ICON_MAP,
  CHARGE_STATUS_COLOR_MAP,
  CHARGE_STATUS_ICON_MAP,
} from "../constants";
import { Stack, Typography } from "@mui/material";

export const ChargeDetails = ({ charge }: { charge: IAdditionalCharge }) => {
  const { isSupplier, paymentMethod } = useAccount();
  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();
  const isBluePenguin = Boolean(isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin);
  return (
    <DetailsList dense sx={{ padding: 0 }}>
      <DetailsListItem xs title="Contract">
        <Link
          sx={{ textDecoration: "none" }}
          color="text.primary"
          to={generatePath(CONTRACT_PATH, { contractId: charge.contractUuid || "" })}
        >
          {charge.contractName}
        </Link>
      </DetailsListItem>

      <DetailsListItem xs title="Service day(s)">
        <span>{dayjs(charge.beginTime).format(DATE_FORMAT_DEFAULT)}</span>
        <span> - </span>
        <span>{dayjs(charge.endTime).format(DATE_FORMAT_DEFAULT)}</span>
      </DetailsListItem>

      <DetailsListItem xs title="Title">
        {charge.title}
      </DetailsListItem>

      <DetailsListItem xs title="Description">
        {charge.description || "-"}
      </DetailsListItem>

      {isBluePenguin && charge.consumerThirdPartyUsdPaymentId ? (
        <DetailsListItem xs title="ACH ID">
          {charge.consumerThirdPartyUsdPaymentId}
        </DetailsListItem>
      ) : null}

      {isSupplier ? (
        <>
          <DetailsListItem xs title="Reference ID/PO No.">
            {charge.referenceId || "-"}
          </DetailsListItem>
          <DetailsListItem xs title="General Ledger Account Number">
            {charge.accountNumber || "-"}
          </DetailsListItem>
        </>
      ) : null}

      {charge.lastPaymentId ? (
        <DetailsListItem xs title="Payment ID">
          {charge.lastPaymentId}
        </DetailsListItem>
      ) : null}

      <DetailsListItem xs title="Amount">
        {charge.amount ? formatDollarAmount(charge.amount) : "N/A"}
      </DetailsListItem>

      {charge.createdBy ? (
        <DetailsListItem xs title="Created by">
          {charge.createdBy}
        </DetailsListItem>
      ) : null}

      {charge.updatedAt && charge.updatedBy ? (
        <DetailsListItem xs title={`${charge.status} by`}>
          {charge.updatedBy || "-"}
        </DetailsListItem>
      ) : null}

      <DetailsListItem xs title="Invoice Status" sx={{ pr: 1 }}>
        <Stack direction="row" py={1}>
          <BadgeTypography
            variant="inherit"
            direction="row-reverse"
            justifyContent="flex-end"
            status={CHARGE_STATUS_COLOR_MAP[charge.status]}
            icon={CHARGE_STATUS_ICON_MAP[charge.status]}
          >
            {charge.status}{" "}
          </BadgeTypography>
          {charge.rejectionReason ? (
            <Typography
              mt={-5}
              pr={3}
              textAlign="right"
              flexGrow={1}
              variant="body3"
              component="span"
            >
              <strong>Reason: </strong>
              <span>{charge.rejectionReason}</span>
            </Typography>
          ) : null}
        </Stack>
      </DetailsListItem>
      {charge.paymentStatus ? (
        <DetailsListItem xs title="Payment Status" sx={{ pr: 1 }}>
          <Stack direction="row" py={1}>
            <BadgeTypography
              variant="inherit"
              direction="row-reverse"
              justifyContent="flex-end"
              status={CHARGE_PAYMENT_STATUS_COLOR_MAP[charge.paymentStatus]}
              icon={CHARGE_PAYMENT_STATUS_ICON_MAP[charge.paymentStatus]}
            >
              {charge.status}{" "}
            </BadgeTypography>
            {charge.rejectionReason ? (
              <Typography
                mt={-5}
                pr={3}
                textAlign="right"
                flexGrow={1}
                variant="body3"
                component="span"
              >
                <strong>Reason: </strong>
                <span>{charge.rejectionReason}</span>
              </Typography>
            ) : null}
          </Stack>
        </DetailsListItem>
      ) : null}
    </DetailsList>
  );
};
