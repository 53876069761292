import { Box } from "@mui/material";
import {
  ButtonRow,
  CreateAdditionalChargeInput,
  DATE_FORMAT_DEFAULT,
  DetailsList,
  DetailsListItem,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  Modal,
  UseModalProps,
  dayjs,
  formatDollarAmount,
} from "@synota-io/synota-shared-ui";

export const ConfirmChargeModal = ({
  open,
  onClose,
  onConfirm,
  contract,
  charge,
  isLoading,
}: UseModalProps & {
  charge: Pick<
    CreateAdditionalChargeInput,
    | "amount"
    | "begin_time"
    | "end_time"
    | "description"
    | "contract_shared_uuid"
    | "general_ledger_account_number"
    | "header_title"
    | "reference_id_po_number"
  >;
  contract: { name?: string | undefined } | null | undefined;
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  if (!contract) {
    return null;
  }

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Please review the information"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit
              color="secondary"
              isLoading={isLoading}
              form="create-additional-charge-form-confirm"
              fullWidth
            >
              Send invoice
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form
        id="create-additional-charge-form-confirm"
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm();
        }}
      >
        <FormStep>
          <DetailsList dense sx={{ padding: 0 }}>
            <DetailsListItem xs title="Contract">
              {contract.name}
            </DetailsListItem>
            <DetailsListItem xs title="Period">
              <span>{dayjs(charge.begin_time).format(DATE_FORMAT_DEFAULT)}</span>
              <span> - </span>
              <span>{dayjs(charge.end_time).format(DATE_FORMAT_DEFAULT)}</span>
            </DetailsListItem>
            <DetailsListItem xs title="Title">
              {charge.header_title}
            </DetailsListItem>
            <DetailsListItem xs title="Description">
              {charge.description || "-"}
            </DetailsListItem>
            <DetailsListItem xs title="Reference ID/PO No.">
              {charge.reference_id_po_number || "-"}
            </DetailsListItem>
            <DetailsListItem xs title="General Ledger Account Number">
              {charge.general_ledger_account_number || "-"}
            </DetailsListItem>
            <DetailsListItem xs title="Amount">
              {charge.amount ? formatDollarAmount(charge.amount) : "N/A"}
            </DetailsListItem>
          </DetailsList>
        </FormStep>
      </Form>
    </Modal>
  );
};
