import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ACCOUNT_TOS_PATH, LOGIN_PATH } from "../../paths";
import { SynotaSpinner, useAccount, useContractsSummary } from "@synota-io/synota-shared-ui";
import { Box, Stack } from "@mui/material";

export const ProtectedPage = ({ children }: PropsWithChildren) => {
  const { isLogged, isLoading, requiresTos } = useAccount();
  const { isFetching: isFetchingContracts } = useContractsSummary();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated((current) => current || (!isLoading && !isFetchingContracts));
  }, [isLoading, isFetchingContracts]);

  if (requiresTos) {
    return <Navigate to={ACCOUNT_TOS_PATH} replace />;
  }

  if (!hydrated) {
    return (
      <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center" gap={8} mb={8}>
          <SynotaSpinner />
        </Stack>
      </Box>
    );
  }

  if (isLogged) {
    return <>{children}</>;
  }

  return <Navigate to={LOGIN_PATH} replace />;
};
