import {
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Tooltip,
} from "@mui/material";
import {
  ActionButton,
  AutoEllipsis,
  BadgeTypography,
  BodyCell,
  BodyRow,
  ButtonRow,
  DateField,
  FormSubmit,
  HeadCell,
  HeadRow,
  IAdditionalCharge,
  Link,
  REPORT_ENDPOINTS,
  SearchField,
  StickyTableContainer,
  formatDate,
  formatDollarAmount,
  isDayjs,
  useAccount,
  useAdditionalCharges,
  useBreakpoint,
  useCounterparties,
  useCurrencyConverter,
  useModal,
  useReportCsv,
  useReportFiltersForm,
  useWindowSize,
  ContractSearchField,
  ReportLayout,
} from "@synota-io/synota-shared-ui";
import { ReactNode, useEffect } from "react";
import { ChargeActionsModal } from "./ChargeActionsModal";
import { ADDITIONAL_CHARGE_PATH, CONTRACT_PATH } from "../../../paths";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { CHARGE_PAYMENT_STATUS_COLOR_MAP, CHARGE_STATUS_COLOR_MAP } from "../constants";
import { Add, Download } from "@mui/icons-material";
import { Slide } from "@mui/material";
import { CreateAdditionalChargeFormModal } from "./CreateAdditionalChargeFormModal";

interface TableField {
  key: string;
  value: (charge: IAdditionalCharge) => ReactNode;
  display: boolean;
}

const defaultDates = {
  startDate: null,
  endDate: null,
};

const RenderContract = (item: IAdditionalCharge) => {
  return item.contractUuid ? (
    <Link
      sx={{ textDecoration: "none" }}
      color="text.primary"
      to={generatePath(CONTRACT_PATH, { contractId: item.contractUuid || "" })}
    >
      <AutoEllipsis>{item.contractName}</AutoEllipsis>
    </Link>
  ) : (
    "N/A"
  );
};

const RenderInvoiceStatus = (item: IAdditionalCharge) => (
  <Tooltip color="primary" title={item.rejectionReason ? `Reason: ${item.rejectionReason}` : ""}>
    <span>
      <BadgeTypography variant="subtitle3" status={CHARGE_STATUS_COLOR_MAP[item.status]}>
        {item.status}
      </BadgeTypography>
    </span>
  </Tooltip>
);

const RenderPaymentStatus = (item: IAdditionalCharge) =>
  item.paymentStatus ? (
    <BadgeTypography
      variant="subtitle3"
      sx={{ textTransform: "capitalize" }}
      status={CHARGE_PAYMENT_STATUS_COLOR_MAP[item.paymentStatus]}
    >
      {item.paymentStatus}
    </BadgeTypography>
  ) : null;

export const AdditionalChargesTable = () => {
  const { search } = useLocation();
  const isTablet = useBreakpoint("sm");
  const navigate = useNavigate();
  const params = useParams();
  const { isAdmin, isSupplier, paymentMethod } = useAccount();

  // Re-renders ellipsis calculation on window resize
  useWindowSize();

  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();
  const isBluePenguin = Boolean(isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin);

  const actionsModal = useModal();
  const createModal = useModal();

  const { control, watch } = useReportFiltersForm({
    defaultReport: REPORT_ENDPOINTS.ADDITIONAL_CHARGES,
    defaultDates,
  });

  const values = watch();

  const {
    params: input,
    download,
    error: csvError,
    isFetching: isFetchingCsv,
  } = useReportCsv({ values });

  const {
    charges,
    isFetching: isFetchingCharges,
    refetch,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useAdditionalCharges(input);

  const { counterparties } = useCounterparties();

  useEffect(() => {
    if (!actionsModal.open && !isFetchingCharges && params.chargeId) {
      actionsModal.onOpen({ chargeId: params.chargeId });
    }
  }, [actionsModal, isFetchingCharges, params.chargeId]);

  const tableFields: TableField[] = [
    {
      key: "Contract",
      value: RenderContract,
      display: isTablet,
    },
    {
      key: "Payment ID",
      value: (item) => item.lastPaymentId,
      display: isTablet,
    },
    {
      value: (item) => (item.amount >= 0 ? formatDollarAmount(item.amount) : null),
      key: "Amount",
      display: true,
    },
    {
      value: (item) => <AutoEllipsis>{item.title}</AutoEllipsis>,
      key: "Title",
      display: isTablet,
    },
    {
      value: (item) => (
        <AutoEllipsis>{`${formatDate(item.beginTime)} - ${formatDate(item.endTime)}`}</AutoEllipsis>
      ),
      key: "Service day(s)",
      display: true,
    },
    {
      value: ({ consumerThirdPartyUsdPaymentId }) =>
        consumerThirdPartyUsdPaymentId ? (
          <AutoEllipsis>{consumerThirdPartyUsdPaymentId}</AutoEllipsis>
        ) : null,
      key: "ACH ID",
      display: isBluePenguin,
    },
    {
      value: RenderInvoiceStatus,
      key: "Invoice Status",
      display: true,
    },
    {
      value: RenderPaymentStatus,
      key: "Payment Status",
      display: charges.some((c) => c.paymentStatus),
    },
    {
      value: (item) => {
        const needsReview = isAdmin && !item.consumerVerified && !item.void && !item.rejected;
        return (
          <Button
            size="small"
            sx={{ padding: 0 }}
            color={needsReview ? "warning" : "primary"}
            variant="contained"
            onClick={() => {
              navigate({
                pathname: generatePath(ADDITIONAL_CHARGE_PATH, { chargeId: item.uuid }),
                search,
              });
            }}
          >
            {needsReview ? "Review" : "Details"}
          </Button>
        );
      },
      key: "Actions",
      display: true,
    },
  ];

  const fields = tableFields.filter((f) => f.display);

  return (
    <ReportLayout
      title="Ad Hoc Invoices"
      actions={
        <>
          <ActionButton
            isLoading={isFetchingCsv}
            disabled={Boolean(csvError || !charges?.length)}
            title="Download as CSV"
            color="primary"
            size="small"
            onClick={() => download()}
          >
            <Download />
          </ActionButton>
          {isSupplier && isAdmin ? (
            <ActionButton
              title="New Invoice"
              color="primary"
              size="small"
              onClick={() => createModal.onOpen()}
            >
              <Add />
            </ActionButton>
          ) : null}
        </>
      }
    >
      <ButtonRow flexWrap="wrap">
        <ButtonRow flexGrow={1}>
          <ContractSearchField
            control={control}
            name="contract"
            label="Filter by Contract"
            variant="filled"
            fullWidth
          />

          {counterparties.length ? (
            <SearchField
              options={counterparties}
              control={control}
              name="counterparty"
              label="Filter by Counterparty"
              variant="filled"
              fullWidth
            />
          ) : null}
        </ButtonRow>
        <ButtonRow flexGrow={1}>
          <DateField
            variant="filled"
            clearable
            maxDate={isDayjs(values.endDate) ? values.endDate : undefined}
            control={control}
            name="startDate"
            label="From Date"
            fullWidth
          />
          <DateField
            variant="filled"
            clearable
            minDate={isDayjs(values.startDate) ? values.startDate : undefined}
            control={control}
            name="endDate"
            label="To Date"
            fullWidth
          />
        </ButtonRow>
      </ButtonRow>
      <Slide in direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {fields.map((field) => (
                      <HeadCell key={field.key}>
                        <AutoEllipsis maxLine={2}>{field.key}</AutoEllipsis>
                      </HeadCell>
                    ))}
                  </HeadRow>
                </TableHead>

                <TableBody>
                  {charges &&
                    charges.map((item) => {
                      return (
                        <BodyRow key={item.id}>
                          {fields.map((field) => (
                            <BodyCell key={item.id + field.key}>{field.value(item)}</BodyCell>
                          ))}
                        </BodyRow>
                      );
                    })}
                </TableBody>

                {!isFetchingCharges && !charges?.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No invoices found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>

              <ChargeActionsModal
                key={params.chargeId}
                onSuccess={() => refetch()}
                charge={charges?.find((c) => c.uuid === params.chargeId) || null}
                {...actionsModal}
                onClose={() =>
                  navigate(
                    { pathname: "..", search },
                    { preventScrollReset: true, relative: "path" },
                  )
                }
              />
            </StickyTableContainer>
            {isSupplier && createModal.open ? (
              <CreateAdditionalChargeFormModal onSuccess={refetch} {...createModal} />
            ) : null}
          </CardContent>
        </Card>
      </Slide>
      <Slide in direction="up">
        <Stack direction="row">
          <FormSubmit
            isLoading={isFetching}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
            variant="contained"
          >
            {isFetching || hasNextPage ? "Load More" : "No more results"}
          </FormSubmit>
        </Stack>
      </Slide>
    </ReportLayout>
  );
};
