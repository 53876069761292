import { styled } from "@mui/material";
import StrikeTextLogo from "../../assets/vendors/strike-text-logo.png";
import { ImgHTMLAttributes } from "react";

const InvertedImage = styled("img")(({ theme }) => ({
  filter: `invert(${Number(theme.palette.mode === "light")})`,
}));

export const StrikeLogo = ({ ...props }: Omit<ImgHTMLAttributes<HTMLImageElement>, "src">) => {
  return <InvertedImage width={54} alt="strike.me" {...props} src={StrikeTextLogo} />;
};
