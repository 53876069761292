import { AccountProvider, useToast } from "@synota-io/synota-shared-ui";
import { QueryClientProvider, QueryClient, keepPreviousData } from "@tanstack/react-query";
import { PropsWithChildren, useCallback } from "react";
import { API_TIMEOUT_MS, SYNOTA_API_URL } from "./utils/environment";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH, LOGIN_PATH } from "./paths";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
      placeholderData: keepPreviousData,
    },
    mutations: {},
  },
});

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const toast = useToast();
  const navigate = useNavigate();

  const onError = useCallback(
    (err: unknown) => {
      Sentry.captureException(err);
      navigate("/internal-server-error");
    },
    [navigate],
  );

  const onLogout = useCallback(() => navigate(LOGIN_PATH), [navigate]);
  const onLogin = useCallback(() => navigate(DASHBOARD_PATH), [navigate]);

  const onAccountSwitch = useCallback(
    (email: string, description: string) => {
      toast.info(`Now viewing as ${description || email}`);
    },
    [toast],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider
        clientMaxTimeout={API_TIMEOUT_MS}
        hostURL={SYNOTA_API_URL}
        onError={onError}
        onLogout={onLogout}
        onLogin={onLogin}
        onAccountSwitch={onAccountSwitch}
      >
        {children}
      </AccountProvider>
    </QueryClientProvider>
  );
};
