import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import { SupplierCurrencyConverterForm } from "../forms/SupplierCurrencyConverterForm";
import { useAccount, useStrikeConfiguration } from "@synota-io/synota-shared-ui";
import { AutomaticDisbursementForm } from "../forms/AutomaticDisbursementForm";

export const SupplierSettings = () => {
  const { isAdmin } = useAccount();
  const { hasAllFeatures } = useStrikeConfiguration();

  return (
    <Grid container spacing={6}>
      <Grid size={{ xs: 12 }}>
        <Card>
          <CardContent>
            <SupplierCurrencyConverterForm showPayoutDetails={isAdmin === false} />
          </CardContent>
        </Card>
      </Grid>
      {hasAllFeatures ? (
        <Grid size={{ xs: 12 }}>
          <Card>
            <CardContent>
              <AutomaticDisbursementForm />
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
};
