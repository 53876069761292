import { Slide, Box, Grid2 as Grid, Stack } from "@mui/material";
import { CashFlowChart } from "../components/CashFlowChart";
import { MetricsTable } from "../components/MetricsTable";
import { BalanceSummary } from "../components/BalanceSummary";
import { useAccount, useBreakpoint } from "@synota-io/synota-shared-ui";

export const DashboardPage = () => {
  const { hasLightningNode } = useAccount();

  const isLg = useBreakpoint("lg");

  if (!hasLightningNode && isLg) {
    return (
      <Grid container spacing={6}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Slide in direction="up">
            <Box width="100%">
              <CashFlowChart />
            </Box>
          </Slide>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Slide in direction="left">
            <Stack width="100%" gap={6}>
              <BalanceSummary />
              <MetricsTable />
            </Stack>
          </Slide>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid size={{ xs: 12 }}>
        <Slide in direction="down">
          <Box width="100%">
            <BalanceSummary />
          </Box>
        </Slide>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Slide in direction="up">
          <Stack width="100%" gap={6}>
            <CashFlowChart />
          </Stack>
        </Slide>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Slide in direction="left">
          <Box width="100%">
            <MetricsTable />
          </Box>
        </Slide>
      </Grid>
    </Grid>
  );
};
