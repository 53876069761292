import { CardContent, Grid2 as Grid, Stack, Typography } from "@mui/material";
import {
  REPORT_ENDPOINTS,
  StatsBox,
  formateDateTime,
  getBitcoinFromSats,
  useCurrentNodeBalance,
  useRevenue,
  useWithdrawals,
} from "@synota-io/synota-shared-ui";
import { DEBUG_ENABLED } from "../../../utils/environment";
import { LocationSummary } from "./LocationSummary";
import { StatCard } from "./StatCard";
import {
  EXPLORER_DEPOSIT_HISTORY_PATH,
  EXPLORER_REPORTS_PATH,
  SETTINGS_PATH,
} from "../../../paths";
import { Link } from "react-router-dom";

export const CurrentNodeBalanceSummary = () => {
  const {
    currentNodeBalance,
    isLoading: isLoadingBalance,
    availableBalance,
    availableBalanceInUSD,
  } = useCurrentNodeBalance();
  const { lastWithdrawal, isLoading: isLoadingWithdrawal } = useWithdrawals();
  const { lastRevenueItem, isLoading: isLoadingRevenue } = useRevenue();

  return (
    <Grid container spacing={6}>
      {DEBUG_ENABLED && (
        <Grid size={{ xs: 12 }}>
          <StatCard>
            <CardContent>
              <StatsBox
                disablePadding
                variant="primary"
                title="Debug"
                items={[
                  <Stack direction="row" key="confirmed" gap={2}>
                    <Typography
                      display="inline"
                      key="confirmations"
                      variant="inherit"
                      color="success.dark"
                    >
                      On-Chain Confirmed
                    </Typography>
                    {currentNodeBalance?.onchainConfirmedBalance} sats
                  </Stack>,
                  <Stack direction="row" key="pending" gap={2}>
                    <Typography
                      display="inline"
                      key="confirmations"
                      variant="inherit"
                      color="warning.dark"
                    >
                      On-Chain Pending
                    </Typography>
                    {currentNodeBalance?.onchainUnconfirmedBalance} sats
                  </Stack>,
                  <Stack direction="row" key="lightning" gap={2}>
                    <Typography
                      display="inline"
                      key="confirmations"
                      variant="inherit"
                      color="info.dark"
                    >
                      Lightning Outbound Capacity
                    </Typography>
                    {currentNodeBalance?.lightningOutboundCapacity} sats
                  </Stack>,
                ]}
              />
            </CardContent>
          </StatCard>
        </Grid>
      )}

      {!isLoadingBalance && availableBalance >= 0 ? (
        <Grid size={{ xs: 6, sm: 3, lg: 3 }}>
          <StatCard>
            <CardContent>
              <StatsBox
                component={Link}
                to={SETTINGS_PATH}
                disablePadding
                flexGrow={1}
                variant="primary"
                title="Current Balance"
                description={`(~${availableBalanceInUSD} USD)`}
                items={[`${getBitcoinFromSats(availableBalance)} BTC`]}
              />
            </CardContent>
          </StatCard>
        </Grid>
      ) : null}
      {!isLoadingRevenue && lastRevenueItem ? (
        <Grid size={{ xs: 6, sm: 3, lg: 3 }}>
          <StatCard>
            <CardContent>
              <StatsBox
                component={Link}
                to={EXPLORER_DEPOSIT_HISTORY_PATH}
                disablePadding
                flexGrow={1}
                variant="primary"
                title="Last Deposit"
                items={[`${getBitcoinFromSats(lastRevenueItem.revenueAmount)} BTC`]}
                description={`${formateDateTime(lastRevenueItem.time)}`}
              />
            </CardContent>
          </StatCard>
        </Grid>
      ) : null}
      {!isLoadingWithdrawal && lastWithdrawal ? (
        <Grid size={{ xs: 6, sm: 3, lg: 3 }}>
          <StatCard>
            <CardContent>
              <StatsBox
                disablePadding
                flexGrow={1}
                component={Link}
                to={{
                  pathname: EXPLORER_REPORTS_PATH,
                  search: `report=${REPORT_ENDPOINTS.WITHDRAWAL_DETAILS.value}`,
                }}
                variant="primary"
                title="Last Withdrawal"
                items={[
                  `${getBitcoinFromSats(lastWithdrawal.amountInSats)} BTC`,
                  <Typography
                    key="confirmations"
                    variant="inherit"
                    color={lastWithdrawal.transactionsConfirmed ? "success.dark" : "warning.dark"}
                  >
                    {lastWithdrawal.transactionsConfirmed ? "Confirmed" : "Pending"}
                  </Typography>,
                ]}
                description={`${formateDateTime(lastWithdrawal.initiatedAt)}`}
              />
            </CardContent>
          </StatCard>
        </Grid>
      ) : null}
      <Grid size={{ xs: 6, sm: 3, lg: 3 }}>
        <LocationSummary
          sx={{
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};
