import { DeleteForever, Edit, EditNote, MoreVert } from "@mui/icons-material";
import {
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { MouseEvent, ReactNode, useCallback, useState } from "react";
import { EditContractModal } from "./EditContractModal";
import {
  ConfirmationModal,
  IContractSummary,
  useAccount,
  useConfirmationModal,
  useContractsSummary,
  useDeleteContract,
  useModal,
  useToast,
} from "@synota-io/synota-shared-ui";
import { EditContractCounterpartyModal } from "./EditContractCounterpartyModal";
import { ContractAction } from "../hooks/useContractActions";

export const ContractMoreMenu = ({
  contract,
  actions,
  ...props
}: {
  contract: IContractSummary;
  actions: ContractAction[];
} & IconButtonProps) => {
  const { refetch } = useContractsSummary();
  const { deleteContract, isPending } = useDeleteContract({ onSuccess: refetch });
  const editModal = useModal();
  const counterpartyModal = useModal();
  const deleteModal = useConfirmationModal();
  const { success } = useToast();
  const { jwt, isAdmin } = useAccount();

  const onDeleteClick = () => {
    deleteModal.onOpen({
      title: `Are you sure that you want to delete the contract "${contract.name}"`,
      onConfirm() {
        deleteContract(
          {
            jwt,
            contract_id: contract.id,
          },
          {
            onSuccess() {
              deleteModal.onClose();
              success("Contract deleted successfully.");
            },
          },
        );
      },
    });
  };

  const [contractMenuAnchor, setContractMenuAnchor] = useState<null | HTMLElement>(null);

  const onOpenContractMenu = (event: MouseEvent<HTMLElement>) => {
    setContractMenuAnchor(event.currentTarget);
  };

  const onCloseContractMenu = () => {
    setContractMenuAnchor(null);
  };

  const onContractMenuItemClick = useCallback((cb: () => void) => {
    setContractMenuAnchor(null);
    cb();
  }, []);

  const elements: Record<ContractAction, ReactNode> = {
    rename: (
      <MenuItem key="rename" onClick={() => onContractMenuItemClick(editModal.onOpen)}>
        <ListItemIcon>
          <Edit color="secondary" fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename Contract</ListItemText>
      </MenuItem>
    ),
    renameCounterparty: (
      <MenuItem
        key="renameCounterparty"
        onClick={() => onContractMenuItemClick(counterpartyModal.onOpen)}
      >
        <ListItemIcon>
          <EditNote color="secondary" fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename Counterparty</ListItemText>
      </MenuItem>
    ),
    delete: (
      <MenuItem key="delete" onClick={() => onContractMenuItemClick(onDeleteClick)}>
        <ListItemIcon>
          <DeleteForever color="error" fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete Contract</ListItemText>
      </MenuItem>
    ),
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <IconButton {...props} onClick={onOpenContractMenu}>
        {props.children || <MoreVert />}
      </IconButton>
      <Menu
        anchorEl={contractMenuAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(contractMenuAnchor)}
        onClose={onCloseContractMenu}
      >
        {Object.entries(elements)
          .filter(([key]) => actions.includes(key as ContractAction))
          .map(([, element]) => element)}
      </Menu>

      {editModal.open ? (
        <EditContractModal {...editModal} onSuccess={refetch} contract={contract} />
      ) : null}

      {counterpartyModal.open ? (
        <EditContractCounterpartyModal
          {...counterpartyModal}
          onSuccess={refetch}
          contract={contract}
        />
      ) : null}

      <ConfirmationModal isLoading={isPending} {...deleteModal} />
    </>
  );
};
