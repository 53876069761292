import {
  FlexLoadingBlock,
  Page,
  useAccount,
  useContractsSummary,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";

export const LoadingPage = ({
  children,
  fullWidth,
}: PropsWithChildren<{ fullWidth?: boolean }>) => {
  const { isLoading } = useAccount();
  const { isLoading: isLoadingContracts } = useContractsSummary();

  if (isLoading || isLoadingContracts) {
    return <FlexLoadingBlock variant="logo" pb={18} />;
  }

  return <Page fullWidth={fullWidth}>{children}</Page>;
};
