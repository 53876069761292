import { CardProps } from "@mui/material";
import { generatePath } from "react-router-dom";
import { CONTRACT_PATH } from "../../../paths";
import { BadgeTypography, IContractSummary, Link } from "@synota-io/synota-shared-ui";
import { ContractContainerCard } from "./ContractContainerCard";

interface Props extends CardProps {
  contract: IContractSummary;
}

export const ActiveContractCard = ({ contract, ...props }: Props) => {
  const link = generatePath(CONTRACT_PATH, {
    contractId: contract.uuid,
  });

  return (
    <ContractContainerCard {...props} contract={contract} link={link}>
      <Link sx={{ textDecoration: "none" }} to={link}>
        <BadgeTypography status="success">{contract.name}</BadgeTypography>
      </Link>
    </ContractContainerCard>
  );
};
