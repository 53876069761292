import { Box, Stack, Tooltip } from "@mui/material";
import {
  ButtonRow,
  ConfirmationModal,
  DateField,
  Dayjs,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  IContractCredit,
  IContractSummary,
  InputField,
  Modal,
  NumericField,
  UseModalProps,
  dayjs,
  formatDollarAmount,
  isDayjs,
  useAccount,
  useConfirmationModal,
  useCreditsMutations,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Help } from "@mui/icons-material";

export interface CreateCreditFormValues {
  contract: { uuid: string; name: string; label: string; value: number } | null;
  usdAmount: number;
  effective_date: Dayjs | null;
  credit_memo_number: string;
  service_start_time: Dayjs | null;
  service_end_time: Dayjs | null;
  title: string;
  description: string;
}

export const createInvoiceSchema = yup.object({
  contract: yup
    .object({
      value: yup.number(),
      label: yup.string(),
      uuid: yup.string().required("Invalid contract"),
      name: yup.string(),
    })
    .required("Contract is required"),
  usdAmount: yup
    .number()
    .typeError("USD Amount is required")
    .min(0, "USD Amount is required")
    .required("USD Amount is required"),
  credit_memo_number: yup.string(),
  effective_date: yup.object().required("Effective date is required"),
  service_start_time: yup.object().required("Period start is required"),
  service_end_time: yup.object().required("Period end is required"),
  description: yup.string(),
  title: yup.string().required("A title is required"),
});

const getFormValues = (contract: IContractSummary, credit?: IContractCredit) => {
  if (credit) {
    return {
      contract: { label: contract.name, value: contract.id, ...contract },
      usdAmount: Number(credit?.amount),
      credit_memo_number: credit?.credit_memo_number,
      effective_date: dayjs(credit?.effective_date),
      service_start_time: dayjs(credit?.service_start_time),
      service_end_time: dayjs(credit?.service_end_time),
      description: credit?.description,
      title: credit?.title,
    };
  }

  return {
    contract: { label: contract.name, value: contract.id, ...contract },
    usdAmount: "" as unknown as number,
    credit_memo_number: "",
    effective_date: dayjs(),
    service_start_time: dayjs(),
    service_end_time: dayjs(),
    description: "",
    title: "",
  };
};

export const CreditFormModal = ({
  contract,
  open,
  onClose,
  onSuccess,
  credit,
}: UseModalProps & {
  onSuccess: () => void;
  contract: IContractSummary;
  credit?: IContractCredit;
}) => {
  const { jwt } = useAccount();
  const { isPending, isPartiallyEditCreditPending, addCredit, partiallyEditCredit } =
    useCreditsMutations();

  const isEditMode = !!credit;

  const [hasSetEndDate, setHasSetEndDate] = useState(false);

  const { control, handleSubmit, watch, setValue, trigger, formState, reset } =
    useForm<CreateCreditFormValues>({
      resolver: yupResolver(createInvoiceSchema) as any,
      defaultValues: isEditMode ? getFormValues(contract, credit) : getFormValues(contract),
    });

  const values = watch();

  const confirmModal = useConfirmationModal();

  const onSubmit = handleSubmit(
    ({ usdAmount, effective_date, service_start_time, service_end_time, ...values }) => {
      if (!contract) {
        return;
      }
      confirmModal.onOpen({
        title: isEditMode
          ? `Update the credit of ${formatDollarAmount(usdAmount)} for contract "${contract.name}"?`
          : `Create a credit of ${formatDollarAmount(usdAmount)} for contract "${contract.name}"?`,
        onConfirm() {
          confirmModal.onClose();

          if (credit) {
            partiallyEditCredit(
              {
                jwt,
                contract_shared_uuid: contract.uuid,
                effective_date: (effective_date as Dayjs).startOf("day").toISOString(),
                service_start_time: (service_start_time as Dayjs).toISOString(),
                service_end_time: (service_end_time as Dayjs).toISOString(),
                credit_shared_uuid: credit.shared_uuid,
                ...values,
              },
              {
                onSuccess: () => {
                  onSuccess();
                },
              },
            );

            return reset(values);
          }

          addCredit(
            {
              jwt,
              amount: String(usdAmount),
              contract_shared_uuid: contract.uuid,
              effective_date: (effective_date as Dayjs).startOf("day").toISOString(),
              service_start_time: (service_start_time as Dayjs).toISOString(),
              service_end_time: (service_end_time as Dayjs).toISOString(),
              ...values,
            },
            {
              onSuccess: () => {
                onSuccess();
                onClose();
              },
            },
          );
        },
      });
    },
  );

  return (
    <>
      <Modal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        title={`${isEditMode ? "Update" : "Add"} credit for "${contract.name}"`}
        actions={
          <ButtonRow width="100%">
            <FormButton
              variant="text"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </FormButton>
            <Box flexGrow={1} display="flex">
              <FormSubmit
                disabled={!formState.isDirty}
                isLoading={isPending || isPartiallyEditCreditPending}
                form="create-credit-form"
                fullWidth
              />
            </Box>
          </ButtonRow>
        }
      >
        <Form id="create-credit-form" onSubmit={onSubmit}>
          <FormStep>
            <InputField
              size="small"
              header="Header/Title"
              autoFocus
              fullWidth
              control={control}
              name="title"
            />

            <ButtonRow>
              <DateField
                header="Service Date(s)"
                size="small"
                label="Start date"
                fullWidth
                maxDate={isDayjs(values.service_end_time) ? values.service_end_time : undefined}
                control={control}
                name="service_start_time"
                onChange={(value) => {
                  if (value && !hasSetEndDate) {
                    setValue("service_end_time", value);
                    trigger("service_end_time");
                  }
                }}
              />
              <DateField
                fullWidth
                header="&nbsp;"
                size="small"
                minDate={isDayjs(values.service_start_time) ? values.service_start_time : undefined}
                label="End date"
                onChange={() => {
                  setHasSetEndDate(true);
                }}
                control={control}
                name="service_end_time"
              />
            </ButtonRow>
            <ButtonRow>
              <NumericField
                size="small"
                header="Amount"
                readOnly={isEditMode}
                disabled={isEditMode}
                fullWidth
                autoComplete="off"
                slotProps={{ input: { endAdornment: "$" } }}
                control={control}
                name="usdAmount"
              />
              <DateField
                size="small"
                header={
                  <Tooltip title="Credit applies on or after energy day for the selected date">
                    <Stack lineHeight={1.3} component="span" direction="row" spacing={2}>
                      <span>Effective Date</span>
                      <span>
                        <Help fontSize="small" />
                      </span>
                    </Stack>
                  </Tooltip>
                }
                fullWidth
                control={control}
                name="effective_date"
              />
            </ButtonRow>
            <InputField
              size="small"
              header="Credit Memo #"
              fullWidth
              autoComplete="off"
              control={control}
              name="credit_memo_number"
            />
            <InputField
              size="small"
              header="Description"
              fullWidth
              control={control}
              name="description"
            />
          </FormStep>
        </Form>
      </Modal>
      {confirmModal.open && <ConfirmationModal {...confirmModal} />}
    </>
  );
};
