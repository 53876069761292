import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Collapse,
  Fade,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CreateAlertInput,
  Form,
  FormButton,
  FormSubmit,
  IAlert,
  IAlertType,
  SearchCreateEmailsField,
  SearchField,
  ToggleField,
  UpdateAlertInput,
  useAccount,
  useAlerts,
  useToast,
} from "@synota-io/synota-shared-ui";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";

interface AlertSchema {
  id?: number;
  type: {
    label: string;
    value: string;
    description?: string;
  };
  frequency?:
    | {
        label: string;
        value: string;
      }
    | undefined;
  threshold?: { value: number; label: string } | undefined;
  emails: Array<{ value: string }>;
}

interface AlertSettingsSchema {
  alerts: Array<AlertSchema>;
}

const OFF_OPTION = { label: "Off", color: "error" } as const;

const schema: yup.ObjectSchema<AlertSettingsSchema> = yup.object({
  alerts: yup
    .array(
      yup.object({
        id: yup.number(),
        type: yup
          .object({
            label: yup.string().required(),
            value: yup.string().required(),
            description: yup.string(),
          })
          .required(),
        frequency: yup.object({ value: yup.string().required(), label: yup.string().required() }),
        threshold: yup
          .object({ value: yup.number().required(), label: yup.string().required() })
          .default(undefined)
          .optional(),
        emails: yup
          .array(yup.object({ value: yup.string().required() }))
          .required()
          .min(1, "You need at least one email"),
      }),
    )
    .required("You don't have any alerts to save"),
});

export const AlertSettingsForm = () => {
  const toast = useToast();
  const { jwt, isAdmin } = useAccount();

  const {
    types,
    alerts,
    refetch,
    getTypeByName,
    createAlert,
    deleteAlert,
    updateAlert,
    isFetching,
    isPending,
  } = useAlerts();

  const { control, watch, handleSubmit, getFieldState, formState, reset, setValue } =
    useForm<AlertSettingsSchema>({
      mode: "all",
      resolver: yupResolver(schema),
      values: {
        alerts:
          types && alerts
            ? (alerts
                .map((a) => {
                  const type = getTypeByName(a.name);
                  if (!type) {
                    return null;
                  }
                  return {
                    id: a.id,
                    emails: a.emails?.map(transformEmail) || [],
                    frequency: transformFrequency(a.frequency),
                    threshold: transformThreshold(a.alertAfterDays),
                    type: type ? transformType(type) : transformType(types[0]),
                  };
                })
                .filter((a) => Boolean(a)) as AlertSchema[])
            : [],
      },
    });

  const alertsArray = useFieldArray({
    control,
    name: "alerts",
  });

  const values = watch();
  const alertsValue = values.alerts;
  const typeOptions = types?.map(transformType) || [];

  const getFrequencyOptions = (options: string[]) =>
    options.map((f) => {
      const option = { value: f, label: f };

      if (f === "Off") {
        return { ...OFF_OPTION, ...option };
      }

      return option;
    });

  const getThresholdOptions = (options: number[]) => options.map(transformThreshold) || [];

  const emptyAlert = {
    emails: [],
    type: typeOptions[0],
    frequency: typeOptions[0]?.frequencyOptions?.length
      ? transformFrequency(typeOptions[0].frequencyOptions[0])
      : undefined,
    threshold: typeOptions[0]?.alertAfterDaysOptions?.length
      ? transformThreshold(typeOptions[0].alertAfterDaysOptions[0])
      : undefined,
  };

  const onSubmit = handleSubmit(async ({ alerts: alertValues }) => {
    const upserts = alertValues.map((alert, i) => {
      if (alert.id) {
        if (getFieldState(`alerts.${i}`).isDirty) {
          return () =>
            new Promise((resolve, reject) => {
              if (!alert.id) {
                return reject();
              }
              updateAlert(
                { jwt, ...getInputFromAlert(alert), id: alert.id },
                { onSuccess: resolve, onError: reject },
              );
            });
        }

        return () => Promise.resolve();
      }

      return () =>
        new Promise((resolve, reject) => {
          createAlert(
            { jwt, ...getInputFromAlert(alert) },
            { onSuccess: resolve, onError: reject },
          );
        });
    });

    const deletions = alerts
      ? alerts.map((alert) => {
          if (alertValues.findIndex((a) => a.id === alert.id) === -1) {
            return () =>
              new Promise((resolve, reject) => {
                deleteAlert({ jwt, id: alert.id }, { onSuccess: resolve, onError: reject });
              });
          }

          return () => Promise.resolve();
        })
      : [];

    for (const promiseFn of upserts.concat(deletions)) {
      try {
        await promiseFn();
      } catch (e) {
        console.error(e);
      }
    }

    await refetch();
    reset(values);
    toast.success("Alerts saved successfully!");
  });

  const checkAlertExists = (current: AlertSchema) =>
    alerts?.find((alert) => alert.id === current.id);

  const getAlertProps = (idx: number): { severity?: AlertProps["severity"]; title?: string } => {
    const exists = checkAlertExists(alertsValue[idx]);
    const isDirty = getFieldState(`alerts.${idx}`).isDirty;

    if (exists) {
      if (isDirty) {
        return { severity: "warning", title: "Unsaved changes" };
      }

      if (exists.frequency === "Off") {
        return { severity: "error", title: "Disabled" };
      }

      return { severity: "success", title: "Active" };
    }

    return { severity: "warning", title: "Unsaved" };
  };

  return (
    <Box>
      <Form onSubmit={onSubmit}>
        {alertsArray.fields.length === 0 && (
          <Collapse in={!isFetching} sx={{ width: "100%" }}>
            {alerts?.length && alerts.length === types?.length ? (
              <Alert severity="warning">
                <AlertTitle>All alerts removed, click save to delete them.</AlertTitle>
              </Alert>
            ) : (
              <Alert severity="info">
                <AlertTitle>There are no alerts configured.</AlertTitle>
              </Alert>
            )}
          </Collapse>
        )}
        {alertsArray.fields.map((alert, idx) => {
          const typeName = alertsValue[idx].type.value;
          const hasThreshold = getTypeByName(typeName)?.alertAfterDaysOptions;
          const hasFrequency = getTypeByName(typeName)?.frequencyOptions;
          const alertProps = getAlertProps(idx);

          return (
            <Box key={alert.id} width="100%">
              <Collapse in>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    <Stack gap={4} width="100%" flexGrow={1}>
                      <Stack
                        mb={3}
                        width="100%"
                        flexGrow={1}
                        direction="row"
                        alignItems="start"
                        gap={6}
                      >
                        <Stack
                          width="100%"
                          flexGrow={1}
                          direction={{ xs: "column", xl: "row" }}
                          gap={6}
                          flexWrap="wrap"
                        >
                          <SearchField
                            header="Type"
                            control={control}
                            readOnly={!isAdmin}
                            color="primary"
                            size="small"
                            sx={{ width: "22em" }}
                            disableClearable
                            name={`alerts.${idx}.type`}
                            onChange={(_event, value) => {
                              const alertType = value as unknown as IAlertType;
                              const afterDaysOpts = alertType.alertAfterDaysOptions;
                              if (afterDaysOpts.length) {
                                setValue(
                                  `alerts.${idx}.threshold`,
                                  transformThreshold(afterDaysOpts[0]),
                                );
                              }

                              const frequencyOpts = alertType.frequencyOptions;
                              if (frequencyOpts.length) {
                                setValue(
                                  `alerts.${idx}.frequency`,
                                  transformFrequency(alertType.frequencyOptions[0]),
                                );
                              }
                            }}
                            options={typeOptions}
                          />

                          <Stack direction="row" gap={6}>
                            <Fade mountOnEnter unmountOnExit in={Boolean(hasFrequency?.length)}>
                              <Stack maxWidth="100%">
                                <ToggleField
                                  header="Frequency"
                                  disabled={!isAdmin}
                                  control={control}
                                  name={`alerts.${idx}.frequency`}
                                  color="success"
                                  size="small"
                                  options={getFrequencyOptions(hasFrequency || [])}
                                />
                              </Stack>
                            </Fade>

                            <Fade mountOnEnter unmountOnExit in={Boolean(hasThreshold?.length)}>
                              <Stack maxWidth="100%">
                                <ToggleField
                                  header="After"
                                  disabled={!isAdmin}
                                  control={control}
                                  name={`alerts.${idx}.threshold`}
                                  color="primary"
                                  size="small"
                                  options={getThresholdOptions(hasThreshold || [])}
                                />
                              </Stack>
                            </Fade>
                          </Stack>
                        </Stack>

                        {isAdmin ? (
                          <Tooltip title="Delete Alert">
                            <span>
                              <IconButton onClick={() => alertsArray.remove(idx)} color="error">
                                <Delete />
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : null}
                      </Stack>

                      <SearchCreateEmailsField
                        control={control}
                        readOnly={!isAdmin}
                        name={`alerts.${idx}.emails`}
                        fullWidth
                        required
                        header="Recipients"
                        label="Type an email and press ENTER"
                        caption="Select emails to send alerts"
                        options={getAvailableEmails(alertsValue, alerts)}
                      />

                      {alertsValue[idx]?.type ? (
                        <Stack width="100%">
                          <Alert sx={{ mt: 2 }} {...alertProps}>
                            <AlertTitle>
                              <b>{alertProps.title}:</b>{" "}
                              {alertsValue[idx]?.type?.description || alertsValue[idx]?.type?.label}
                            </AlertTitle>
                          </Alert>
                        </Stack>
                      ) : null}
                    </Stack>
                  </CardContent>
                </Card>
              </Collapse>
            </Box>
          );
        })}
        {formState.errors.root && (
          <Typography color="error" variant="body1">
            {formState.errors.root.message}
          </Typography>
        )}
        {formState.errors.alerts && (
          <Typography color="error" variant="body1">
            {formState.errors.alerts.message}
          </Typography>
        )}
        {isAdmin ? (
          <Stack direction="row" width="100%" justifyContent="space-between">
            <FormButton
              disabled={isFetching || isPending}
              onClick={() => alertsArray.append(emptyAlert)}
            >
              Add New Alert
            </FormButton>
            <Stack direction="row" gap={2}>
              {formState.isDirty ? (
                <FormButton
                  variant="text"
                  color="error"
                  onClick={() => {
                    if (window.confirm("Discard changes?")) {
                      reset();
                    }
                  }}
                >
                  Discard Changes
                </FormButton>
              ) : null}
              <FormSubmit disabled={!formState.isDirty} isLoading={isFetching || isPending}>
                Save
              </FormSubmit>
            </Stack>
          </Stack>
        ) : null}
      </Form>
    </Box>
  );
};

const transformEmail = (e: string) => ({ value: e });
const transformType = (t: IAlertType) => ({ value: t.name, ...t });
const transformFrequency = (f: string) => ({ value: f, label: f });
const transformThreshold = (t: number) => ({ value: t, label: t === 1 ? "1 day" : t + " days" });

const getAvailableEmails = (values: AlertSchema[], alerts: IAlert[] | null) => {
  const draftEmails = values?.flatMap((alert) => alert.emails.map((e) => e.value) || []);
  const savedEmails = alerts?.flatMap((alert) => alert.emails || []);

  const emails =
    draftEmails.concat(savedEmails || []).sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }) || [];

  return Array.from(new Set(emails)).map((e) => ({ value: e }));
};

const getInputFromAlert = (
  value: AlertSchema,
): Omit<CreateAlertInput | UpdateAlertInput, "jwt"> => {
  const emails = value.emails.map((e) => e.value);
  return {
    alert_after_n_days: value.threshold?.value || 0,
    alert_frequency: value.frequency?.value || "Off",
    alert_name: value.type.value,
    emails,
    space_delimited_emails: emails.join(" "),
  };
};
