import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import {
  BadgeTypography,
  ButtonRow,
  EStrikeOAuthFeature,
  formatDollarAmount,
  FormButton,
  RevealTypography,
  useAccount,
  useAutomaticPayments,
  useCountryStates,
  useCurrencyConverter,
  useDisbursementConfiguration,
  useModal,
} from "@synota-io/synota-shared-ui";
import { StrikeFeatureForm } from "./StrikeFeatureForm";
import { DISBURSEMENT_FREQUENCY_OPTIONS } from "./AutomaticDisbursementForm";
import { CurrencyConverterAchFormModal } from "../modals/CurrencyConverterAchFormModal";
import { PropsWithChildren } from "react";
import { StrikeLogo } from "../../../shared/media/StrikeLogo";

interface Props {
  showPayoutDetails: boolean;
}

export const SupplierCurrencyConverterForm = ({ showPayoutDetails }: Props) => {
  const { isAdmin } = useAccount();

  const {
    isStrike,
    isSdm,
    isBluePenguin,
    configurations,
    isFetching,
    refetch: refetchCurrencyConverter,
  } = useCurrencyConverter();

  const { configuration: disbursementConfiguration, refetch: refetchDisbursement } =
    useDisbursementConfiguration();

  const { refetchPayout } = useAutomaticPayments();

  const onConnection = () => {
    refetchCurrencyConverter();
    refetchDisbursement();
    refetchPayout();
  };

  const { getSelectedOption } = useCountryStates();

  const frequencyOption = DISBURSEMENT_FREQUENCY_OPTIONS.find(
    (o) => o.value === disbursementConfiguration?.payoutWindowInSeconds,
  );

  const bluePenguinModal = useModal();

  if (isBluePenguin) {
    return (
      <Stack spacing={6}>
        <Typography variant="h6">Bank Account</Typography>
        {!configurations?.BluePenguinConfiguration ? null : (
          <Stack direction="row" gap={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <InfoRow title="ACH Routing Number">
                    <RevealTypography hint hintLength={4} disabled={!isAdmin}>
                      {configurations.BluePenguinConfiguration.ach_routing_number}
                    </RevealTypography>
                  </InfoRow>

                  <InfoRow title="ACH Account Number">
                    <RevealTypography hint hintLength={4} disabled={!isAdmin}>
                      {configurations.BluePenguinConfiguration.ach_account_number}
                    </RevealTypography>
                  </InfoRow>

                  <InfoRow title="Account Type">
                    {configurations.BluePenguinConfiguration.additional_info?.account_type}
                  </InfoRow>

                  <InfoRow title="Company Name">
                    {configurations.BluePenguinConfiguration.additional_info?.company_name}
                  </InfoRow>

                  <InfoRow title="City">
                    {configurations.BluePenguinConfiguration.additional_info?.city}
                  </InfoRow>

                  <InfoRow title="State">
                    {
                      getSelectedOption(
                        configurations.BluePenguinConfiguration.additional_info?.state,
                      )?.label
                    }
                  </InfoRow>

                  <InfoRow title="Country">
                    {configurations.BluePenguinConfiguration.additional_info?.country}
                  </InfoRow>

                  <InfoRow title="Zip Code">
                    {configurations.BluePenguinConfiguration.additional_info?.postal_code}
                  </InfoRow>

                  <InfoRow title="Address">
                    {configurations.BluePenguinConfiguration.additional_info?.address}
                  </InfoRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
        {isAdmin ? (
          <ButtonRow>
            <FormButton
              isLoading={isFetching}
              type="button"
              variant="contained"
              size="small"
              onClick={() => bluePenguinModal.onOpen()}
            >
              Update Bank Account
            </FormButton>
          </ButtonRow>
        ) : null}
        {bluePenguinModal.open && <CurrencyConverterAchFormModal {...bluePenguinModal} />}
      </Stack>
    );
  }

  return (
    <Stack spacing={6}>
      <Typography variant="h6">Currency Conversion</Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Provider</TableCell>
              <TableCell>
                {isStrike && <StrikeLogo />}
                {isSdm && <span>SDM</span>}
              </TableCell>
            </TableRow>
            {disbursementConfiguration ? (
              <>
                <TableRow>
                  <TableCell>Automatic Disbursements</TableCell>
                  <TableCell>
                    {disbursementConfiguration.enabled ? (
                      <BadgeTypography variant="subtitle4" status="success">
                        Enabled
                      </BadgeTypography>
                    ) : (
                      <BadgeTypography variant="subtitle4" status="error">
                        Disabled
                      </BadgeTypography>
                    )}
                  </TableCell>
                </TableRow>
                {disbursementConfiguration.enabled ? (
                  <>
                    <TableRow>
                      <TableCell>Disbursement Account</TableCell>
                      <TableCell>{disbursementConfiguration.accountName}</TableCell>
                    </TableRow>
                    {showPayoutDetails && (
                      <>
                        <TableRow>
                          <TableCell>Payment Frequency</TableCell>
                          <TableCell>
                            {disbursementConfiguration.payoutWindowLabel}{" "}
                            {frequencyOption ? (
                              <Typography
                                pl={1}
                                display="inline"
                                fontSize="small"
                                color="text.secondary"
                              >
                                ({frequencyOption.caption})
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Minimum Payout</TableCell>
                          <TableCell>
                            {formatDollarAmount(disbursementConfiguration.minPayout)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      {isStrike ? (
        <StrikeFeatureForm
          features={[
            EStrikeOAuthFeature.AutomaticPayout,
            EStrikeOAuthFeature.FromStrikePayments,
            EStrikeOAuthFeature.GetBalance,
          ]}
          onConnect={onConnection}
          onDisconnect={onConnection}
        />
      ) : null}
    </Stack>
  );
};

const InfoRow = ({ children, title }: PropsWithChildren<{ title: string }>) => {
  if (!children) {
    return null;
  }
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell sx={{ fontFamily: "monospace", fontSize: "0.9em", textTransform: "capitalize" }}>
        {children}
      </TableCell>
    </TableRow>
  );
};
