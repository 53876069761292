import {
  CONTRACTS_PATH,
  CONTRACT_CREDITS_PATH,
  CONTRACT_EXPOSURE_PATH,
  CONTRACT_PATH,
} from "../../../paths";
import { BreadLinkProps } from "../components/Breadcrumbs";
import { useParams, matchPath, useLocation } from "react-router-dom";
import { useContractDetails, useContractsSummary } from "@synota-io/synota-shared-ui";

export const useBreadcrumbs = () => {
  const { contractId } = useParams();
  const { pathname } = useLocation();

  const { find } = useContractsSummary();
  const contract = find(contractId);

  // Preload contract details
  useContractDetails({ uuid: contractId });

  const links: BreadLinkProps[] = [];

  links.push({
    title: "Contracts",
    path: CONTRACTS_PATH,
  });

  if (contract) {
    links.push(
      {
        title: contract.name,
        path: CONTRACT_PATH,
        params: {
          contractId: contract.uuid,
        },
      },
      {
        title: "Exposure",
        path: CONTRACT_EXPOSURE_PATH,
        params: {
          contractId: contract.uuid,
        },
      },
      {
        title: "Credits",
        path: CONTRACT_CREDITS_PATH,
        params: {
          contractId: contract.uuid,
        },
      },
    );
  }

  const matchedLinks = links.filter(
    ({ path }) => !path || Boolean(matchPath({ path, end: false }, pathname)),
  );

  return { links, matchedLinks };
};
