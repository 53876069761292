import {
  CollapsedCard,
  DetailsList,
  DetailsListItem,
  IContract,
  useAccount,
} from "@synota-io/synota-shared-ui";
import { useContractCustomFields } from "../hooks/useContractCustomFields";
import { ReserveAmountHeld } from "./ReserveAmountHeld";
import { Skeleton } from "@mui/material";

interface Props {
  contract: IContract | null;
  isLoading?: boolean;
}

export const ContractDetails = ({ contract, isLoading }: Props) => {
  const { hasLightningNode, isConsumer } = useAccount();

  const contractFields = useContractCustomFields(contract);

  if (isLoading) {
    return <Skeleton variant="rounded" height={50.25} />;
  }

  if (!contract) {
    return null;
  }

  return (
    <CollapsedCard title="Contract Terms">
      <DetailsList>
        {contractFields
          .filter((field) => !!field.value)
          .map((field) => {
            return (
              <DetailsListItem key={field.label} title={field.label}>
                {field.value}
              </DetailsListItem>
            );
          })}
        {contract.customDescriptions.length ? null : (
          <>
            {isConsumer && hasLightningNode && (
              <DetailsListItem title="Deposit Address">
                {contract?.lastDepositAddress}
              </DetailsListItem>
            )}
            <ReserveAmountHeld contract={contract} />
          </>
        )}
      </DetailsList>
    </CollapsedCard>
  );
};
