import { Typography, Alert, AlertTitle, Button, CircularProgress, Stack } from "@mui/material";

import {
  ButtonRow,
  EStrikeOAuthFeature,
  useAccount,
  useCurrentUsdBalance,
  useExternalPopup,
  useStrikeConfiguration,
  useToast,
} from "@synota-io/synota-shared-ui";
import { useEffect } from "react";
import { StrikeLogo } from "../../../shared/media/StrikeLogo";

interface Props {
  features?: Array<EStrikeOAuthFeature>;
  onDisconnect?(): void;
  onConnect?(): void;
}

export const StrikeFeatureForm = ({
  features = [
    EStrikeOAuthFeature.AutomaticPayout,
    EStrikeOAuthFeature.FromStrikePayments,
    EStrikeOAuthFeature.GetBalance,
    EStrikeOAuthFeature.AchPayments,
  ],
  onDisconnect = () => {},
  onConnect = () => {},
}: Props) => {
  const { jwt, isAdmin } = useAccount();
  const { warning } = useToast();

  const { open, reset, externalPopup, hasClosed } = useExternalPopup();

  const { description } = useCurrentUsdBalance();

  const {
    hasAllFeatures,
    hasSomeFeatures,
    featureOAuthUrl,
    isFetching: isLoadingStrike,
    refetch,
    getFeatureOAuthUrl,
    logoutOAuth,
  } = useStrikeConfiguration({ features });

  useEffect(() => {
    if (hasClosed && !hasAllFeatures && featureOAuthUrl) {
      refetch();
      onConnect();
      reset();
    }
  }, [hasClosed, featureOAuthUrl, hasAllFeatures, onConnect, refetch, reset]);

  const onConnectToStrikeClick = () => {
    getFeatureOAuthUrl(features, {
      onSuccess: (response: any) => {
        if (!hasAllFeatures && response.data) {
          open({
            url: response.data,
            title: "Authorize your Strike Account",
            height: screen.height ? screen.height - 140 : 1024,
            width: 600,
          });
        }
      },
    });
  };

  const onLogoutStrikeClick = () => {
    logoutOAuth(
      { jwt },
      {
        onSuccess: () => {
          reset();
          refetch();
          onDisconnect();
          warning("Strike account is now disconnected");
        },
      },
    );
  };

  const isPopupLoading = externalPopup && !hasClosed && !hasAllFeatures;
  const isConnectDisabled = Boolean(!isAdmin || isLoadingStrike || isPopupLoading);

  return (
    <Stack gap={4}>
      <ButtonRow spacing={2} alignItems="center" minHeight="3em">
        {hasSomeFeatures ? (
          <>
            <Stack>
              <Alert severity="success">
                <AlertTitle>
                  <StrikeLogo />
                </AlertTitle>
                <Typography variant="subtitle1">Account Linked</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {description}
                </Typography>
              </Alert>
            </Stack>
            {!isLoadingStrike && isAdmin && (
              <Button
                disabled={isConnectDisabled}
                size="small"
                color="error"
                onClick={onLogoutStrikeClick}
              >
                Disconnect
              </Button>
            )}
          </>
        ) : (
          <Button
            disabled={isConnectDisabled}
            variant="contained"
            size="small"
            onClick={onConnectToStrikeClick}
          >
            Connect to Strike
          </Button>
        )}
        {isLoadingStrike || isPopupLoading ? <CircularProgress size={32} /> : null}
      </ButtonRow>
    </Stack>
  );
};
